import http from '@/utils/request/http'

// 添加音乐
export const addMusic = (params = {}) => {
	return http.post('/api/addMusic', params)
}

export const addBeatMusic = (params = {}) => {
	return http.post('/api/addBeatMusic', params)
}
//音调字典
export const keyList = (params = {}) => {
	return http.get('/api/keyList', params)
}
//注册音乐人
export const registerCreator = (params = {}) => {
	return http.post('/api/registerCreator', params)
}
//国家列表
export const getCountryList = (params = {}) => {
	return http.get('/api/countryList', params)
}

